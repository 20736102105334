import {useEffect,useState} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {useAuth} from './core/Auth'
import axios, {AxiosResponse} from 'axios'


const API_URL = process.env.REACT_APP_THEME_API_URL
const logout_url = `${API_URL}/auth/logout`
// const [loggedIn, setLoggedIn] = useState(true);
export function Logout () {
  axios.post(logout_url)
      .then((response) => {
        console.log('Logouted');
        
      })
      .catch((error) => {
        // Handle errors, e.g., show an error message to the user
        console.error('Logout failed', error);
      });

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  )
}
