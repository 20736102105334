/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'
import {useIntl} from "react-intl";

const loginSchema = Yup.object().shape({
  city: Yup.string()
    .required('City is required'),
  code: Yup.string()
    .required('Code is required'),
})

const initialValues = {
  city: '',
  code: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const intl = useIntl()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.city, values.code)
        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>{intl.formatMessage({id: 'Sign In'})}</h1>
        <div className='text-gray-500 fw-semibold fs-6'>{intl.formatMessage({id: 'Jalimex Food'})}</div>
      </div>
      {/* begin::Heading */}

      {formik.status ? (
<div className='mb-lg-15 alert alert-danger'>
<div className='alert-text font-weight-bold'>{intl.formatMessage({id: formik.status})}</div>
</div>
) : ''}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>{intl.formatMessage({id: 'City'})}</label>
        <input
          
          {...formik.getFieldProps('city')}
          className='form-control bg-transparent'
          
          type='city'
          name='city'
          autoComplete='off'
        />
        {formik.touched.city && formik.errors.city && (
          <div className='fv-plugins-message-container'>
             <div className='fv-help-block'>
            <span role='alert'>{intl.formatMessage({id: formik.errors.city})}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label className='form-label fw-bolder text-dark fs-6 mb-0'>{intl.formatMessage({id: 'Code'})}</label>
        <input
          type='code'
          autoComplete='off'
          {...formik.getFieldProps('code')}
          className='form-control bg-transparent'
            
        />
        {formik.touched.code && formik.errors.code && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{intl.formatMessage({id: formik.errors.code})}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

     

      {/* begin::Wrapper */}
      <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
        <div />

        {/* begin::Link */}

        
        
        {/* end::Link */}
      </div>
      {/* end::Wrapper */}

      {/* begin::Action */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>{intl.formatMessage({id: 'Continue'})}</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'Please wait...'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}

    </form>
  )
}
